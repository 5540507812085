@import "@/assets/scss/style.scss";

.wrapperLeadDetails {
    .row {
        display: flex;
        margin-bottom: 30px;
        align-items: flex-end;

        & > * {
            flex: 1 1 33%;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (max-width: 520px) {
    .wrapperLeadDetails {
        .lead-wrapper {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .wrapperTwoRowWithTag {
                margin-bottom: 1rem;
            }
        }
    }

    .extra-flex-div {
        display: none;
    }
}

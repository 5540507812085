@import "@/assets/scss/style.scss";

.wrapperProjectAttachmentsNew {
    position: relative;

    .wrapper {
        &::v-deep {
            .ant-upload-list-picture {
                display: flex;
                flex-wrap: wrap;

                & > * {
                    flex: 0 1 33%;
                    margin-right: 8px;

                    &:nth-child(3) {
                        margin-right: 0;
                    }
                }
            }

            .ant-upload-list-picture .ant-upload-list-item-thumbnail {
                height: 21px;
                width: 21px;
                top: 0;
            }

            .ant-upload-list-picture .ant-upload-list-item-name {
                padding-left: 24px;
                line-height: 32px;
            }

            .ant-upload-list-item-card-actions.picture {
                top: 20px;
            }

            .ant-upload-list-picture .ant-upload-list-item {
                height: 56px;
            }

            .ant-upload.ant-upload-drag {
                border: none;
                background: transparent;

                .ant-upload-text {
                    @extend .u-b1;
                    color: $grey-7;
                }
            }
        }
    }

    .attachments__upload-overlay {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        backdrop-filter: blur(2px);
        z-index: 100;

        .prograss-bar__wrapper {
            width: 70%;
            margin-bottom: 15rem;
        }
    }
}

@import "@/assets/scss/style.scss";

.wrapperProjectTabLead {
    & > * {
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
